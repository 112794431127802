import React from "react";
import Navbar from "../Navbar";
import HeaderBa from "./HeaderBa";
import Projects from "./ProjectsBa";
import Footer from "../Footer";
function Boden() {
  return (
    <div className=" ">
      <Navbar />
      <HeaderBa />
      <Projects />
      <Footer />
    </div>
  );
}
export default Boden;
