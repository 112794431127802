import ML1 from "./../../media/Malerarbeiten/ML1.jpeg"
import ML2 from "./../../media/Malerarbeiten/ML2.jpeg"
import ML3 from "./../../media/Malerarbeiten/ML3.jpeg"
import ML4 from "./../../media/Malerarbeiten/ML4.jpeg"
import ML5 from "./../../media/Malerarbeiten/ML5.jpeg"
import ML6 from "./../../media/Malerarbeiten/ML6.jpeg"
import ML7 from "./../../media/Malerarbeiten/ML7.jpeg"
import ML8 from "./../../media/Malerarbeiten/ML8.jpeg"
import ML9 from "./../../media/Malerarbeiten/ML9.jpeg"
import ML10 from "./../../media/Malerarbeiten/ML10.jpeg"
import ML11 from "./../../media/Malerarbeiten/ML11.jpeg"
import ML12 from "./../../media/Malerarbeiten/ML12.jpeg"


const DataM =[
  // {
  //     category: "Malerarbeiten",
  //     image: ML1,
  //     title: "Card 1",
  //     // additional_images: [
  //     //   B2,
  //     //   B3,
  //     //   B4,
  //     //   B2,
  //     //   B3,
  //     //   B9,
  //     //   B2,
  //     //   B3,
  //     //   B9,
  //     //   B10,
  //     //   B2,
  //     //   B3
  //     // ]
    
  // },
  {
    category: "Malerarbeiten",
    image: ML1,
    title: "Malerarbeiten",
  },
  {
    category: "Malerarbeiten",
    image: ML2,
    title: "Malerarbeiten",
  },
  {
    category: "Malerarbeiten",
    image: ML3,
    title: "Malerarbeiten",
  },
  {
    category: "Malerarbeiten",
    image: ML4,
    title: "Malerarbeiten",
  },
  {
    category: "Malerarbeiten",
    image: ML5,
    title: "Malerarbeiten",
  },
  {
    category: "Malerarbeiten",
    image: ML6,
    title: "Malerarbeiten",
  },
  {
    category: "Malerarbeiten",
    image: ML7,
    title: "Malerarbeiten",
  },
  {
    category: "Malerarbeiten",
    image: ML8,
    title: "Malerarbeiten",
  },
  {
    category: "Malerarbeiten",
    image: ML9,
    title: "Malerarbeiten",
  },
  {
    category: "Malerarbeiten",
    image: ML10,
    title: "Malerarbeiten",
  },
  {
    category: "Malerarbeiten",
    image: ML11,
    title: "Malerarbeiten",
  },
  {
    category: "Malerarbeiten",
    image: ML12,
    title: "Malerarbeiten",
  },


]

export default DataM;