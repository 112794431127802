import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import DataG from "../../json/Gebäudereinigung/DataG";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/effect-fade";

import "./HeaderFl.css";
// import required modules
import { Autoplay, EffectFade } from "swiper/modules";
function Header() {
  return (
    <div className=" md:mt-10 md:mb-10 ">
      <div className=" md:grid grid-cols-2 ">
        <div
          data-aos="fade-left"
          data-aos-duration="1200"
          className="block md:hidden col-span-1"
        >
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            effect={"fade"}
            autoplay={{
              delay: 2500, // Match the progress bar animation duration
              disableOnInteraction: true,
            }}
            modules={[Autoplay, EffectFade]}
            className="mySwiper3"
          >
            {DataG.map((item, index) => (
              <SwiperSlide key={index}>
                <img src={item.image} alt={item.title} />
                <div className="absolute abstract-shapeS bottom-0 md:bottom-0 ">
                  <p className="text-white  text-base md:text-xl  font-bold mx-auto">
                    {item.title}
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className=" col-span-1 ml-8 md:ml-24 mr-8 md:mr-24">
          <div
            data-aos="fade-right"
            data-aos-duration="1200"
            className="mt-10 md:mt-10"
          >
            <div className=" mx-auto ">
              <h1 className="text-3xl md:text-5xl text-palette  font-bold mt-0 md:mt-20 mb-3 md:mb-6">
                Gebäudereinigung{" "}
              </h1>
              <p className="text-palette md:pr-20 text-base  md:text-xl mt-0 md:mt-10  mb-4 md:mb-0">
                Wir haben die richtige Reinigungslösung für praktisch alles.
                Sprechen Sie uns an.
                <ul className="text-paletteof font-semibold list-disc text-base ml-5  mt-2">
                  <li>Umzugsreinigung mit Abgabegarantie.</li>
                  <li>Neu- und Umbaureinigungen.</li>
                  <li>Schaufensterreinigungen.</li>
                  <li>Textile Bodenbeläge shampoonieren und sprühextrahieren / Fleckenbehandlung.</li>
                  <li>
                  Unterhaltsreinigungen von Büros / Praxen / Industrie / Gewerbe.
                  </li>
                  <li>
                  Räumungen und Entsorgungen.</li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <div
          data-aos="fade-left"
          data-aos-duration="1200"
          className=" col-span-1 hidden md:block ml-8 md:ml-24 mr-8 md:mr-24 md:-mt-10"
        >
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            effect={"fade"}
            autoplay={{
              delay: 2500, // Match the progress bar animation duration
              disableOnInteraction: true,
            }}
            modules={[Autoplay, EffectFade]}
            className="mySwiper"
          >
            {DataG.map((item, index) => (
              <SwiperSlide key={index}>
                <img src={item.image} alt={item.title} />
                <div className="absolute abstract-shapeS bottom-0 md:bottom-0 ">
                  <p className="text-white  text-base md:text-xl  font-bold mx-auto">
                    {item.title}
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
export default Header;
