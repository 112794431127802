import BL1 from "./../../media/Bodenbeläge/BL1.jpeg";
import BL2 from "./../../media/Bodenbeläge/BL2.jpeg";
import BL3 from "./../../media/Bodenbeläge/BL3.jpeg";
import BL4 from "./../../media/Bodenbeläge/BL4.jpeg";
import BL5 from "./../../media/Bodenbeläge/BL5.jpeg";
import BL6 from "./../../media/Bodenbeläge/BL6.jpeg";
import BL7 from "./../../media/Bodenbeläge/BL7.jpeg";
import BL8 from "./../../media/Bodenbeläge/BL8.jpeg";
import BL9 from "./../../media/Bodenbeläge/BL9.jpeg";
import BL10 from "./../../media/Bodenbeläge/BL10.jpeg";
import BL11 from "./../../media/Bodenbeläge/BL11.jpeg";
import BL12 from "./../../media/Bodenbeläge/BL12.jpeg";
import BL13 from "./../../media/Bodenbeläge/BL13.jpeg";
import BL14 from "./../../media/Bodenbeläge/BL14.jpeg";
import BL15 from "./../../media/Bodenbeläge/BL15.jpeg";
import BL16 from "./../../media/Bodenbeläge/BL16.jpeg";
import BL17 from "./../../media/Bodenbeläge/BL17.jpeg";
import BL18 from "./../../media/Bodenbeläge/BL18.jpeg";
import BL19 from "./../../media/Bodenbeläge/BL19.jpeg";
import BL20 from "./../../media/Bodenbeläge/BL20.jpeg";
import BL21 from "./../../media/Bodenbeläge/BL21.jpeg";
import BL22 from "./../../media/Bodenbeläge/BL22.jpeg";
import BL23 from "./../../media/Bodenbeläge/BL23.jpeg";
import BL24 from "./../../media/Bodenbeläge/BL24.jpeg";
import BL25 from "./../../media/Bodenbeläge/BL25.jpeg";
import BL26 from "./../../media/Bodenbeläge/BL26.jpeg";
import BL27 from "./../../media/Bodenbeläge/BL27.jpeg";
import BL28 from "./../../media/Bodenbeläge/BL28.jpeg";
import BL29 from "./../../media/Bodenbeläge/BL29.jpeg";
import BL30 from "./../../media/Bodenbeläge/BL30.jpeg";
import BL31 from "./../../media/Bodenbeläge/BL31.jpeg";
import BL32 from "./../../media/Bodenbeläge/BL32.jpeg";
import BL33 from "./../../media/Bodenbeläge/BL33.jpeg";
import BL34 from "./../../media/Bodenbeläge/BL34.jpeg";
import BL35 from "./../../media/Bodenbeläge/BL35.jpeg";
import BL36 from "./../../media/Bodenbeläge/BL36.jpeg";
import BL37 from "./../../media/Bodenbeläge/BL37.jpeg";
import BL38 from "./../../media/Bodenbeläge/BL38.jpeg";
import BL39 from "./../../media/Bodenbeläge/BL39.jpeg";
import BL40 from "./../../media/Bodenbeläge/BL40.jpeg";

const DataB = [
  // {
  //     category: "Bodenbeläge",
  //     image: BL1,
  //     title: "Card 1",
  //     // additional_images: [
  //     //   B2,
  //     //   B3,
  //     //   B4,
  //     //   B2,
  //     //   B3,
  //     //   B9,
  //     //   B2,
  //     //   B3,
  //     //   B9,
  //     //   B10,
  //     //   B2,
  //     //   B3
  //     // ]

  // },
  {
    category: "Bodenbeläge",
    image: BL1,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL2,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL3,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL4,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL5,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL6,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL7,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL8,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL9,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL10,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL11,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL12,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL13,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL14,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL15,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL16,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL17,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL18,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL19,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL20,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL21,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL22,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL23,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL24,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL25,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL26,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL27,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL28,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL29,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL30,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL31,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL32,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL33,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL34,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL35,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL36,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL37,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL38,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL39,
    title: "Bodenbeläge",
  },
  {
    category: "Bodenbeläge",
    image: BL40,
    title: "Bodenbeläge",
  },
];

export default DataB;
