import GE1 from "./../../media/Gebäudereinigung/GE1.jpeg";
import GE2 from "./../../media/Gebäudereinigung/GE2.jpeg";
import GE3 from "./../../media/Gebäudereinigung/GE3.jpeg";
import GE4 from "./../../media/Gebäudereinigung/GE4.jpeg";
import GE5 from "./../../media/Gebäudereinigung/GE5.jpeg";
import GE6 from "./../../media/Gebäudereinigung/GE6.jpeg";
import GE7 from "./../../media/Gebäudereinigung/GE7.jpeg";
import GE8 from "./../../media/Gebäudereinigung/GE8.jpeg";
import GE9 from "./../../media/Gebäudereinigung/GE9.jpeg";
import GE10 from "./../../media/Gebäudereinigung/GE10.jpeg";
import GE11 from "./../../media/Gebäudereinigung/GE11.jpeg";
import GE12 from "./../../media/Gebäudereinigung/GE12.jpeg";
import GE13 from "./../../media/Gebäudereinigung/GE13.jpeg";
import GE14 from "./../../media/Gebäudereinigung/GE14.jpeg";
import GE15 from "./../../media/Gebäudereinigung/GE15.jpeg";
import GE16 from "./../../media/Gebäudereinigung/GE16.jpeg";
import GE17 from "./../../media/Gebäudereinigung/GE17.jpeg";
import GE18 from "./../../media/Gebäudereinigung/GE18.jpeg";
import GE19 from "./../../media/Gebäudereinigung/GE19.jpeg";
import GE20 from "./../../media/Gebäudereinigung/GE20.jpeg";
import GE21 from "./../../media/Gebäudereinigung/GE21.jpeg";
import GE22 from "./../../media/Gebäudereinigung/GE22.jpeg";
import GE23 from "./../../media/Gebäudereinigung/GE23.jpeg";
import GE24 from "./../../media/Gebäudereinigung/GE24.jpeg";
import GE25 from "./../../media/Gebäudereinigung/GE25.jpeg";
import GE26 from "./../../media/Gebäudereinigung/GE26.jpeg";
import GE27 from "./../../media/Gebäudereinigung/GE27.jpeg";
import GE28 from "./../../media/Gebäudereinigung/GE28.jpeg";
import GE29 from "./../../media/Gebäudereinigung/GE29.jpeg";
import GE30 from "./../../media/Gebäudereinigung/GE30.jpeg";
import GE31 from "./../../media/Gebäudereinigung/GE31.jpeg";
import GE32 from "./../../media/Gebäudereinigung/GE32.jpeg";
import GE33 from "./../../media/Gebäudereinigung/GE33.jpeg";
import GE34 from "./../../media/Gebäudereinigung/GE34.jpeg";
import GE35 from "./../../media/Gebäudereinigung/GE35.jpeg";

const DataG = [
  // {
  //     category: "Gebäudereinigung",
  //     image: GE1,
  //     title: "Card 1",
  //     // additional_images: [
  //     //   B2,
  //     //   B3,
  //     //   B4,
  //     //   B2,
  //     //   B3,
  //     //   B9,
  //     //   B2,
  //     //   B3,
  //     //   B9,
  //     //   B10,
  //     //   B2,
  //     //   B3
  //     // ]

  // },
  {
    category: "Gebäudereinigung",
    image: GE1,
    title: "Gebäudereinigung",
  },
  {
    category: "Gebäudereinigung",
    image: GE2,
    title: "Gebäudereinigung",
  },
  {
    category: "Gebäudereinigung",
    image: GE3,
    title: "Gebäudereinigung",
  },
  {
    category: "Gebäudereinigung",
    image: GE4,
    title: "Gebäudereinigung",
  },
  {
    category: "Gebäudereinigung",
    image: GE5,
    title: "Gebäudereinigung",
  },
  {
    category: "Gebäudereinigung",
    image: GE34,
    title: "Gebäudereinigung",
  },
  {
    category: "Gebäudereinigung",
    image: GE35,
    title: "Gebäudereinigung",
  },
  {
    category: "Gebäudereinigung",
    image: GE6,
    title: "Gebäudereinigung",
  },
  {
    category: "Gebäudereinigung",
    image: GE7,
    title: "Gebäudereinigung",
  },
  {
    category: "Gebäudereinigung",
    image: GE8,
    title: "Gebäudereinigung",
  },
  {
    category: "Gebäudereinigung",
    image: GE9,
    title: "Gebäudereinigung",
  },
  {
    category: "Gebäudereinigung",
    image: GE10,
    title: "Gebäudereinigung",
  },
  {
    category: "Gebäudereinigung",
    image: GE11,
    title: "Gebäudereinigung",
  },
  {
    category: "Gebäudereinigung",
    image: GE12,
    title: "Gebäudereinigung",
  },
  {
    category: "Gebäudereinigung",
    image: GE13,
    title: "Gebäudereinigung",
  },
  {
    category: "Gebäudereinigung",
    image: GE14,
    title: "Gebäudereinigung",
  },
  {
    category: "Gebäudereinigung",
    image: GE15,
    title: "Gebäudereinigung",
  },
  {
    category: "Gebäudereinigung",
    image: GE16,
    title: "Gebäudereinigung",
  },
  {
    category: "Gebäudereinigung",
    image: GE17,
    title: "Gebäudereinigung",
  },
  {
    category: "Gebäudereinigung",
    image: GE18,
    title: "Gebäudereinigung",
  },
  {
    category: "Gebäudereinigung",
    image: GE19,
    title: "Gebäudereinigung",
  },
  {
    category: "Gebäudereinigung",
    image: GE20,
    title: "Gebäudereinigung",
  },
  {
    category: "Gebäudereinigung",
    image: GE21,
    title: "Gebäudereinigung",
  },
  {
    category: "Gebäudereinigung",
    image: GE22,
    title: "Gebäudereinigung",
  },
  {
    category: "Gebäudereinigung",
    image: GE23,
    title: "Gebäudereinigung",
  },
  {
    category: "Gebäudereinigung",
    image: GE24,
    title: "Gebäudereinigung",
  },
  {
    category: "Gebäudereinigung",
    image: GE25,
    title: "Gebäudereinigung",
  },
  {
    category: "Gebäudereinigung",
    image: GE26,
    title: "Gebäudereinigung",
  },
  {
    category: "Gebäudereinigung",
    image: GE27,
    title: "Gebäudereinigung",
  },
  {
    category: "Gebäudereinigung",
    image: GE28,
    title: "Gebäudereinigung",
  },
  {
    category: "Gebäudereinigung",
    image: GE29,
    title: "Gebäudereinigung",
  },
  {
    category: "Gebäudereinigung",
    image: GE30,
    title: "Gebäudereinigung",
  },
  {
    category: "Gebäudereinigung",
    image: GE31,
    title: "Gebäudereinigung",
  },
  {
    category: "Gebäudereinigung",
    image: GE32,
    title: "Gebäudereinigung",
  },
  {
    category: "Gebäudereinigung",
    image: GE33,
    title: "Gebäudereinigung",
  },



];

export default DataG;
