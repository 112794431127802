import React from "react";
import logo from "./../media/logo.png"

function Footer() {
  return (
    <div className=" sm:h-auto h-auto border-t-2 border-palette">
      <footer class="p-2  sm:p-2 ">
        <div class="md:flex md:justify-center flex justify-center">
          <div class=" flex justify-center items-center">
            <div class="mb-6 md:mb-0">
              <a className="flex justify-center" href="#">
                <img
                  src={logo}
                  className="h-20  sm:h-28 hover:scale-105 "
                  alt="Sb Group Logo"
                />
              </a>
              <div className="flex justify-center space-x-2 mt-2 md:mt-0 text-palette -ml-1">
                <a
                  href="tel:+41766122244"
                  className="hover:scale-105 text-palette "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-8 md:w-7 h-8 md:h-7"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M14.25 9.75v-4.5m0 4.5h4.5m-4.5 0l6-6m-3 18c-8.284 0-15-6.716-15-15V4.5A2.25 2.25 0 014.5 2.25h1.372c.516 0 .966.351 1.091.852l1.106 4.423c.11.44-.054.902-.417 1.173l-1.293.97a1.062 1.062 0 00-.38 1.21 12.035 12.035 0 007.143 7.143c.441.162.928-.004 1.21-.38l.97-1.293a1.125 1.125 0 011.173-.417l4.423 1.106c.5.125.852.575.852 1.091V19.5a2.25 2.25 0 01-2.25 2.25h-2.25z"
                    />
                  </svg>
                </a>
                <a
                  href="mailto:Info@sb-group.ch"
                  className="hover:scale-105 text-palette mx-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-8 md:w-7 h-8 md:h-7"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                    />
                  </svg>
                </a>

                {/* <a
                  href="#"
                  target="_blank"
                  className="hover:scale-105 text-white"
                  rel="noreferrer"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="w-8 md:w-7 h-8 md:h-7"
                  >
                    <rect
                      x="2"
                      y="2"
                      width="20"
                      height="20"
                      rx="5"
                      ry="5"
                    ></rect>
                    <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                    <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                  </svg>
                </a> */}
              </div>
            </div>
          </div>
        </div>
        <div class="-mt-2 md:mt-0 text-center">
          <span class="text-base font-bold text-palette  text-center ">
          Alle Rechte Vorbehalten. Angetrieben von{" "}
            <a
              href="https://www.sparkling-hub.tech/"
              target="{_blank}"
              className=" text-white text-lg font-extrabold hover:scale-105"
            >
              Sparklinghub
            </a>
            .
          </span>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
