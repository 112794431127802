import React from "react";
import Navbar from "../Navbar";
import Header from "./HeaderBl";
import Projects from "./Projects";
import Footer from "../Footer";
function Malerarbeiten() {
  return (
    <div className=" ">
      <Navbar />
      <Header />
      <Projects />
      <Footer />
    </div>
  );
}
export default Malerarbeiten;
