import React from "react";
import Navbar from "../Navbar";
import Hero from "./Hero";
import Services from "./Services";
import Refer from "./Refer";
import Contact from "./Contact";
import Footer from "../Footer";
function Home() {
  return (
    <div className=" ">
        <Navbar/>
        <Hero/>
        <Services/>
        <Refer/>
        <Contact/>
        <Footer/>
    </div>
  );
}
export default Home;
