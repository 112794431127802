//Bodenbelage
import BL1 from "./../media/Bodenbeläge/BL1.jpeg";
import BL2 from "./../media/Bodenbeläge/BL2.jpeg";
import BL3 from "./../media/Bodenbeläge/BL3.jpeg";
import BL4 from "./../media/Bodenbeläge/BL4.jpeg";
import BL5 from "./../media/Bodenbeläge/BL5.jpeg";
import BL6 from "./../media/Bodenbeläge/BL6.jpeg";
//Gebäudereinigung
import GE1 from "./../media/Gebäudereinigung/GE1.jpeg";
import GE2 from "./../media/Gebäudereinigung/GE2.jpeg";
import GE3 from "./../media/Gebäudereinigung/GE3.jpeg";
import GE4 from "./../media/Gebäudereinigung/GE4.jpeg";
import GE5 from "./../media/Gebäudereinigung/GE5.jpeg";
import GE6 from "./../media/Gebäudereinigung/GE6.jpeg";
//Hauswartung
import HA1 from "./../media/Hauswartung/HA1.jpeg"
import HA2 from "./../media/Hauswartung/HA2.jpeg"
import HA3 from "./../media/Hauswartung/HA3.jpeg"
import HA4 from "./../media/Hauswartung/HA4.jpeg"
import HA5 from "./../media/Hauswartung/HA5.jpeg"
import HA6 from "./../media/Hauswartung/HA6.jpeg"
//Malerarbeiten
import ML1 from "./../media/Malerarbeiten/ML1.jpeg"
import ML2 from "./../media/Malerarbeiten/ML2.jpeg"
import ML3 from "./../media/Malerarbeiten/ML3.jpeg"
import ML4 from "./../media/Malerarbeiten/ML4.jpeg"
import ML5 from "./../media/Malerarbeiten/ML5.jpeg"
import ML6 from "./../media/Malerarbeiten/ML6.jpeg"

const DataS = [
  {
    category: "Bodenbeläge",
    image: BL1,
    title: "Bodenbeläge",
  },
  {
    category: "Malerarbeiten",
    image: ML1,
    title: "Malerarbeiten",
  },
  {
    category: "Gebäudereinigung",
    image: GE1,
    title: "Gebäudereinigung",
  },
  {
    category: "Hauswartung",
    image: HA1,
    title: "Hauswartung",
  },
  {
    category: "Bodenbeläge",
    image: BL2,
    title: "Bodenbeläge",
  },
  {
    category: "Malerarbeiten",
    image: ML2,
    title: "Malerarbeiten",
  },
  {
    category: "Gebäudereinigung",
    image: GE2,
    title: "Gebäudereinigung",
  },
  {
    category: "Hauswartung",
    image: HA2,
    title: "Hauswartung",
  },
  {
    category: "Bodenbeläge",
    image: BL3,
    title: "Bodenbeläge",
  },
  {
    category: "Malerarbeiten",
    image: ML3,
    title: "Malerarbeiten",
  },
  {
    category: "Gebäudereinigung",
    image: GE3,
    title: "Gebäudereinigung",
  },
  {
    category: "Hauswartung",
    image: HA3,
    title: "Hauswartung",
  },
  {
    category: "Bodenbeläge",
    image: BL4,
    title: "Bodenbeläge",
  },
  {
    category: "Malerarbeiten",
    image: ML4,
    title: "Malerarbeiten",
  },
  {
    category: "Gebäudereinigung",
    image: GE4,
    title: "Gebäudereinigung",
  },
  {
    category: "Hauswartung",
    image: HA4,
    title: "Hauswartung",
  },
  {
    category: "Bodenbeläge",
    image: BL5,
    title: "Bodenbeläge",
  },
  {
    category: "Malerarbeiten",
    image: ML5,
    title: "Malerarbeiten",
  },
  {
    category: "Gebäudereinigung",
    image: GE5,
    title: "Gebäudereinigung",
  },
  {
    category: "Hauswartung",
    image: HA5,
    title: "Hauswartung",
  },
  {
    category: "Bodenbeläge",
    image: BL6,
    title: "Bodenbeläge",
  },
  {
    category: "Malerarbeiten",
    image: ML6,
    title: "Malerarbeiten",
  },
  {
    category: "Gebäudereinigung",
    image: GE6,
    title: "Gebäudereinigung",
  },
  {
    category: "Hauswartung",
    image: HA6,
    title: "Hauswartung",
  },
 
];
export default DataS;
